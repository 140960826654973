import React from "react";
import Hero from "../../components/Home/Hero";
import Skills from "../../components/Home/Skills";
import Course from "../../components/Home/Course";
import WorksStep from "../../components/Home/WorksStep";
import Testimonials from "../../components/Home/Testimonials";
import CompanyCard from "../../components/Home/companyCard";
import PlayStore from "../../components/PlayStoreBanner/index"

function index() {
  return (
    <>
      <div className="home bg-cover-img">
        <Hero />
      </div>
      <CompanyCard />
      <Skills />
      <Course />
      <WorksStep />
      <Testimonials />
      <PlayStore />
    </>
  );
}

export default index;
