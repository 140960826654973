import React, { useState } from "react";
import "./index.css";
import Icon1 from "./img/octicon_person-24.png";
import Icon2 from "./img/carbon_email.png";
// import Icon3 from "./img/ic_round-business (1).png";
import Icon4 from "./img/hugeicons_call.png";
import avatar1 from "../../../assets/avatart/avatar1.jpg";
import avatar2 from "../../../assets/avatart/avatar2.jpg";
import avatar3 from "../../../assets/avatart/avatar3.jpg";
import avatar4 from "../../../assets/avatart/avatar4.jpg";
import avatar5 from "../../../assets/avatart/avatar5.jpg";
import { Button } from "react-bootstrap";
import { FaRegClock } from "react-icons/fa";
import { CiCalendarDate } from "react-icons/ci";
import { LiaCertificateSolid } from "react-icons/lia";
// import { useParams } from "react-router-dom";
// import cousredetails from "../../../coursedetails.json" 
import { useFormik } from "formik";
import * as Yup from "yup";
import OtpInput from "react-otp-input";
import axios from 'axios';
import { toast } from "react-toastify";

function Index() {
  // const [isSubmitted, setIsSubmitted] = useState(false);
  // const [otp, setOtp] = useState("");
  // const formik = useFormik({
  //   initialValues: {
  //     companyName: "",
  //     enquiryName: "",
  //     email: "",
  //     phone: "",
  //   },
  //   validationSchema: Yup.object({
  //     companyName: Yup.string()
  //       .min(2, "Company name must be at least 2 characters")
  //       .max(50, "Company name must be 50 characters or less")
  //       .required("Company name is required"),
  //     enquiryName: Yup.string()
  //       .min(2, "Full name must be at least 2 characters")
  //       .max(50, "Full name must be 50 characters or less")
  //       .required("Full name is required"),
  //     email: Yup.string()
  //       .email("Invalid email address")
  //       .matches(/^\S*$/, "Email should not contain spaces")
  //       .matches(
  //         /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/,
  //         "Email must contain one '@', a dot ('.'), and at least two letters after the dot"
  //       )
  //       .test(
  //         "single-at-sign",
  //         "Email should not contain multiple '@' characters",
  //         (value) => (value.match(/@/g) || []).length <= 1
  //       )
  //       .required("Email is required"),
  //     phone: Yup.string()
  //       .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
  //       .required("Mobile number is required"),
  //   }),
  //   onSubmit: (values, { resetForm }) => {
  //     console.log(values);
  //     resetForm();
  //     setIsSubmitted(true);
  //   },
  // });

  // const handleOtpChange = (otp) => {
  //   setOtp(otp);
  // };

  // const handleOtpSubmit = () => {
  //   // Handle OTP submission
  //   console.log("OTP Submitted:", otp);
  // };

  // const { id } = useParams();
  // const course = cousredetails.find((course) => course.id === parseInt(id));

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [otp, setOtp] = useState("");

  const apiEndpoint = process.env.REACT_APP_COMPANY_BANNER_API_ENDPOINT;

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .min(2, "First name must be at least 2 characters")
        .max(50, "First name must be 50 characters or less")
        .required("First name is required"),
      lastName: Yup.string()
        .min(2, "Last name must be at least 2 characters")
        .max(50, "Last name must be 50 characters or less")
        .required("Last name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .matches(/^\S*$/, "Email should not contain spaces")
        .matches(
          /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/,
          "Email must contain one '@', a dot ('.'), and at least two letters after the dot"
        )
        .test(
          "single-at-sign",
          "Email should not contain multiple '@' characters",
          (value) => (value.match(/@/g) || []).length <= 1
        )
        .required("Email is required"),
      phone: Yup.string()
        .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
        .required("Mobile number is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try{
        console.log(values)
        // const response = await axios.post('http://139.59.21.5/api/users/company', values);
        const response = await axios.post(apiEndpoint, values);
        console.log('Form submitted successfully:', response.data);
        toast.success("Form submitted successfully!");
        setIsSubmitted(true);
        resetForm(); 
      } catch(error){
        console.error('There was an error submitting the form!', error);
        const errorMessage = error.response?.data?.message || "Error submitting form. Please try again.";
        toast.error(errorMessage);
      }
    },
  });

  const handleOtpChange = (otp) => {
    setOtp(otp);
  };

  const handleOtpSubmit = () => {
    // Handle OTP submission
    console.log("OTP Submitted:", otp);
  };
  return (

    <React.Fragment>
      <section id="AboutHero1" className="AboutHero">
        <div className="container about-hero px-0 py-5">
          <div className="row no-gutters pb-5">
            <div className="col-lg-6 col-md-12 col-sm-12 left-section1 px-5 pt-5">
              {/* <h1 className="hdl-ac">Master Web Development:</h1> */}
              {/* <h1 className="hdl-ac">{course.title}</h1> */}
              <h1 className="hdl-ac1">
                Build Websites from Scratch to Advanced!
              </h1>
              <p className="pt-3 sub-ac">
                Join our comprehensive web development course to become a
                full-stack developer.
              </p>
              <div className="btn-program">
                <Button className="button"><FaRegClock className="icon"/>12 Week Program</Button>
                <Button className="button" ><CiCalendarDate className="icon"/>1 Hr/day(Flexible)</Button>
                <Button className="button"><LiaCertificateSolid className="icon"/>Get Certifed</Button>
                <Button className="button"><LiaCertificateSolid className="icon"/>Placement Assistance</Button>
              </div>
              <div className="student-enroll">
                <div className="avatar-group">
                  <img src={avatar1} alt="Avatar 1" className="avatar1" />
                  <img src={avatar2} alt="Avatar 2" className="avatar1" />
                  <img src={avatar3} alt="Avatar 3" className="avatar1" />
                  <img src={avatar4} alt="Avatar 4" className="avatar1" />
                  <img src={avatar5} alt="Avatar 5" className="avatar1" />
                </div>
                <p className="text">10000+ Student already enrolled...</p>
              </div>
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12 right-section pt-5">
              <div className="form-container mx-4 pt-5 px-5">
                <>
                  {!isSubmitted ? (
                    <>
                      <p className="py-2 form-headline">
                        Provide your contact information below. We'll get in
                        contact
                      </p>
                      <form onSubmit={formik.handleSubmit}>
                        <div className="mb-4 input-group input-group-border">
                          <span className="input-group-text">
                            <img
                              src={Icon1}
                              alt="Mobile Icon"
                              className="icon-img"
                            />
                          </span>
                          <input
                            type="text"
                            className="form-control no-border"
                            id="firstName"
                            placeholder="Enter your first name"
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            {...formik.getFieldProps("firstName")}
                          />
                        </div>
                        {formik.touched.firstName &&
                        formik.errors.firstName ? (
                          <div
                            className="error mx-3 text-danger"
                            style={{ marginTop: "-20px", marginBottom: "5px" }}
                          >
                            {formik.errors.firstName}
                          </div>
                        ) : null}
                        <div className="mb-4 input-group input-group-border">
                          <span className="input-group-text">
                            <img
                              src={Icon1}
                              alt="Full Name Icon"
                              className="icon-img"
                            />
                          </span>
                          <input
                            type="text"
                            className="form-control no-border"
                            id="lastName"
                            placeholder="Enter your last name"
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            {...formik.getFieldProps("lastName")}
                          />
                        </div>
                        {formik.touched.lastName &&
                        formik.errors.lastName ? (
                          <div
                            className="error mx-3 text-danger"
                            style={{ marginTop: "-20px", marginBottom: "5px" }}
                          >
                            {formik.errors.lastName}
                          </div>
                        ) : null}
                        <div className="mb-4 input-group input-group-border">
                          <span className="input-group-text">
                            <img
                              src={Icon2}
                              alt="Email Icon"
                              className="icon-img"
                            />
                          </span>
                          <input
                            type="email"
                            className="form-control no-border"
                            id="email"
                            placeholder="Enter your email address"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            {...formik.getFieldProps("email")}
                          />
                        </div>
                        {formik.touched.email && formik.errors.email ? (
                          <div
                            className="error mx-3 text-danger"
                            style={{ marginTop: "-20px", marginBottom: "5px" }}
                          >
                            {formik.errors.email}
                          </div>
                        ) : null}
                        <div className="mb-1 input-group input-group-border">
                          <span className="input-group-text">
                            <img
                              src={Icon4}
                              alt="Mobile Icon"
                              className="icon-img"
                            />
                          </span>
                          <input
                            type="tel"
                            className="form-control no-border"
                            id="phone"
                            maxLength="10"
                            placeholder="Enter your mobile number"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            {...formik.getFieldProps("phone")}
                          />
                        </div>
                        {formik.touched.phone && formik.errors.phone ? (
                          <div className="error mx-3 text-danger">
                            {formik.errors.phone}
                          </div>
                        ) : null}
                        <div className="px-4">
                          <button
                            type="submit"
                            className="btn btn-connect full-width-btn"
                          >
                            <span>Enroll Now</span>{" "}
                            <i className="bi bi-chevron-compact-right"></i>
                          </button>
                        </div>
                      </form>
                    </>
                  ) : (
                    <div className="otp-validation">
                     <div className="otp-title"><h5>Enter your OTP Code</h5></div>
                      <div className="otp-input-container">
                        <OtpInput
                          className="otp"
                          value={otp}
                          onChange={handleOtpChange}
                          numInputs={4}
                          renderSeparator={<span>-</span>}
                          renderInput={(props) => (
                            <input
                              {...props}
                              style={{
                                width: "50px",
                                height: "50px", 
                                fontSize: "20px",
                                textAlign: "center",
                                margin: "0 5px",
                                border: "2px solid #2D2F80",
                                borderRadius: "5px"
                                
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="px-4">
                        <button
                          type="button"
                          className="btn btn-validate full-width-btn"
                          onClick={handleOtpSubmit}
                        >
                          <span>Verify</span>{" "}
                        </button>
                      </div>
                    </div>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Index;
