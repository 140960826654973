import React from "react";
import img1 from '../../assets/img/workstep/work1.png'
import img2 from '../../assets/img/workstep/work2.png'
import img3 from '../../assets/img/workstep/work3.png'
import img4 from '../../assets/img/workstep/work4.png'

function WorksStep() {
  return (
    <>
      <section id="work" className="work">
        <div className="title">
          {" "}
          <h2>How it Works?</h2>
        </div>
        <div className="work-bg">
          <div className="container">
            <div className="work-div">
              <div className="work-div2">
                <img src={img1} alt="" />
                <div className="work-div3">
                  <h6>Step 1</h6>
                  <p>
                    Begin your journey by discovering a variety of companies
                  </p>
                </div>
              </div>
              <div className="work-div2">
                <img src={img2} alt="" />
                <div className="work-div3">
                  <h6>Step 2</h6>
                  <p>
                    Dive into detailed job profiles to understand the roles,
                    responsibilities, and skills required.
                  </p>
                </div>
              </div>
              <div className="work-div2">
                <img src={img3} alt="" />
                <div className="work-div3">
                  <h6>Step 3</h6>
                  <p>
                    Create a personalized wishlist of companies you’re
                    interested in to keep track of your top choices.
                  </p>
                </div>
              </div>
              <div className="work-div2">
                <img src={img4} alt="" />
                <div className="work-div3">
                  <h6>Step 4</h6>
                  <p>
                    Finalize your journey by applying and securing your position
                    with your chosen company.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WorksStep;
