import React from "react";
import "./index.css";
import img1 from '../../../assets/img/c1.png'
import img2 from '../../../assets/img/c2.png'
import img3 from '../../../assets/img/c3.png'
import img4 from '../../../assets/img/c4.png'

function WorksStep() {
  return (
    <>
      <section id="work-signup" className="work">
        <div className="title">
          {" "}
          <h2>How it Works?</h2>
        </div>
        <div className="work-bg">
          <div className="container">
            <div className="work-div">
              <div className="work-div2">
                <img src={img1} alt="" />
                <div className="work-div3">
                  <h6>Step 1</h6>
                  <p>
                    Sign Up
                  </p>
                </div>
              </div>
              <div className="work-div2">
                <img src={img2} alt="" />
                <div className="work-div3">
                  <h6>Step 2</h6>
                  <p>
                    Post
                  </p>
                </div>
              </div>
              <div className="work-div2">
                <img src={img3} alt="" />
                <div className="work-div3">
                  <h6>Step 3</h6>
                  <p>
                    Review
                  </p>
                </div>
              </div>
              <div className="work-div2">
                <img src={img4} alt="" />
                <div className="work-div3">
                  <h6>Step 4</h6>
                  <p>
                    Select
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WorksStep;
