import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaFacebook, FaLinkedin, FaInstagram, FaYoutube, FaPhone, FaHome, FaBolt, FaBookOpen, FaBriefcase } from "react-icons/fa";
import { MdOutlinePrivacyTip } from "react-icons/md";
import logo from "../../../assets/logo/ownprep-white 1.png";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();

  // Function to handle navigation and scroll to top
  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer className="py-4 footer">
      <Container>
        <Row>
          <Col md={2} className="text-center text-md-start mb-3 mb-md-0">
            <img src={logo} alt="OwnPrep" />
          </Col>
          <Col md={8} className="text-center mb-3 mb-md-0">
            <div>
              <ul className="list-unstyled">
                <li>
                  <a onClick={() => handleNavigation("/")} className="text-light" style={{ cursor: "pointer" }}>
                    <FaHome className="icon" style={{ marginRight: "8px" }} />
                    Home
                  </a>
                </li>
                <li>
                  <a onClick={() => handleNavigation("/skillboost")} className="text-light" style={{ cursor: "pointer" }}>
                    <FaBolt className="icon" style={{ marginRight: "8px" }} />
                    Skill Boost
                  </a>
                </li>
                <li>
                  <a onClick={() => handleNavigation("/course")} className="text-light" style={{ cursor: "pointer" }}>
                    <FaBookOpen className="icon" style={{ marginRight: "8px" }} />
                    Courses
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <ul className="list-unstyled">
                <li>
                  <a onClick={() => handleNavigation("/aboutcompany")} className="text-light" style={{ cursor: "pointer" }}>
                    <FaBriefcase className="icon" style={{ marginRight: "8px" }} />
                    Employer
                  </a>
                </li>
                <li>
                  <a onClick={() => handleNavigation("/free-bootcamp")} className="text-light" style={{ cursor: "pointer" }}>
                    <FaPhone className="icon" style={{ marginRight: "8px" }} />
                    Contact Us
                  </a>
                </li>
                <li>
                  <a onClick={() => handleNavigation("/privacy_policy")} className="text-light" style={{ cursor: "pointer" }}>
                    <MdOutlinePrivacyTip className="icon" style={{ marginRight: "8px" }} />
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col md={2} className="text-center text-md-right footer-social">
            <p className="text-light">Follow Us:</p>
            <div className="d-flex justify-content-center justify-content-md-end">
              <a href="https://facebook.com" className="text-light mx-2">
                <FaFacebook className="icon" style={{ fontSize: "2em" }} />
              </a>
              <a href="https://linkedin.com" className="text-light mx-2">
                <FaLinkedin className="icon" style={{ fontSize: "2em" }} />
              </a>
              <a href="https://instagram.com" className="text-light mx-2">
                <FaInstagram className="icon" style={{ fontSize: "2em" }} />
              </a>
              <a href="https://youtube.com" className="text-light mx-2">
                <FaYoutube className="icon" style={{ fontSize: "2em" }} />
              </a>
            </div>
          </Col>
        </Row>
        <p className="footer-year text-center text-light">
          ©{currentYear} Ownprep, All Copyright reserved.
        </p>
      </Container>
    </footer>
  );
};

export default Footer;
