/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { IoArrowBackSharp } from "react-icons/io5";
import { GoLink } from "react-icons/go";
import { LuMapPin } from "react-icons/lu";
import { CiHeart } from "react-icons/ci";
import PlayStore from "../../components/PlayStoreBanner/index"
// import { CiShare2 } from "react-icons/ci";

// import { IoIosArrowForward } from "react-icons/io";
// import { FaRegStar } from "react-icons/fa";
// import { FiClock } from "react-icons/fi";

// import img1 from "../../assets/img/course/course1.png";
// import img2 from "../../assets/img/course/course2.png";
// import img3 from "../../assets/img/course/course3.png";

// import get1 from "../../assets/img/get1.png";
// import get2 from "../../assets/img/get2.png";
// import get3 from "../../assets/img/get3.png";

// import imga1 from "../../assets/img/app1.jpeg";
// import imga2 from "../../assets/img/app2.jpeg";
// import imga3 from "../../assets/img/app3.jpeg";

// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import jobDetails from "../../JobDetails.json";
import { Button } from "react-bootstrap";

function index() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  const job = jobDetails.find((job) => job.id === parseInt(id));

  // const courses = [
  //   {
  //     img: img1,
  //     title: "Web Development",
  //     duration: "6 Months",
  //     rating: "4.2 (1,000)",
  //   },
  //   {
  //     img: img2,
  //     title: "Data Science",
  //     duration: "8 Months",
  //     rating: "4.5 (2,000)",
  //   },
  //   {
  //     img: img3,
  //     title: "Data Science",
  //     duration: "8 Months",
  //     rating: "4.5 (2,000)",
  //   },
  // ];

  // const testData = [
  //   { imgSrc: get1, title: "Aptitude tests (5)" },
  //   { imgSrc: get2, title: "Technical tests" },
  //   { imgSrc: get3, title: "Coding tests" },
  // ];
  return (
    <>
      <section id="aboutjob" className="aboutjob mb-5">
        <div className="container">
          <div className="title">
            <Link to="/">
              <Button className="button">
                <IoArrowBackSharp className="mb-1" />
              </Button>
            </Link>
            <h2>{job.title}</h2>
          </div>

          <div className="aboutjob-card">
            <div className="card card1">
              <div className="card-avatar">
                <div className="avatar">
                  <img src={job.imgUrl} alt="Avatar" />
                </div>
              </div>

              <div className="about-description">
                <div className="info">
                  <h4>{job.title}</h4>
                  <h6>{job.designation}</h6>
                  <p>
                    <LuMapPin className="mx-1" />
                    {job.lacation}
                  </p>
                </div>
                <div className="info-btn">
                  <a href={job.link} target="_blank" rel="noopener noreferrer">
                    <Button className="button2 mb-2">
                      <GoLink className="mx-2 icon" />
                      Website
                    </Button>
                  </a>
                  <br />
                  <Button className="button2">
                    <CiHeart className="mx-1 icon" /> Wishlist
                  </Button>
                </div>
              </div>
            </div>

            <div className="card card2">
              <div className="about-comp mb-2">
                <h4>About Company:</h4>
                <p>{job.about}</p>
              </div>
              <div className="about-profile mb-2">
                <h4>Profile:</h4>
                <p>{job.profile}</p>
              </div>
              <div className="about-elibility mb-2">
                <h4>Eligibility:</h4>
                <ul>
                  {job.eligibility.map((item, index) => (
                    <li key={index}>
                      <p>{item.value}</p>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="skills-card">
                <div className="card tech-card">
                  <h6>Technical Skills:</h6>
                  {/* <div className="button-container">
                    <Button className="button">{job.primaryskills}</Button>

                  </div> */}
                  <div className="button-container">
                    {job.primaryskills.split(", ").map((skill, index) => (
                      <Button key={index} className="button">
                        {skill}
                      </Button>
                    ))}
                  </div>
                </div>
                <div className="card soft-card">
                  <h6>Soft Skills:</h6>
                  <div className="button-container">
                    {job.secondaryskills.split(", ").map((skill, index) => (
                      <Button key={index} className="button">
                        {skill}
                      </Button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-2 get-test-app"><img src={imga1} alt="" /></div>
                <div className="col-lg-2 get-test-app"><img src={imga2} alt="" /></div>
                <div className="col-lg-2 get-test-app"><img src={imga3} alt="" /></div> */}
            {/* <div className="get-tested">
              <h4>Get tested:</h4>
              <div className="row get-tested-card">
                <div className="col-lg-6">
                 <img src="" alt="" />
                </div>
               
               
                <Swiper
                  spaceBetween={10}
                  slidesPerView={1}
                  autoplay={{
                    delay: 1000,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 50,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 50,
                    },
                  }}
                >
                  {testData.map((test, index) => (
                    <SwiperSlide key={index}>
                      <div className="col-md-4">
                        <div className="card card-test1">
                          <img src={test.imgSrc} alt="test-img" />
                        </div>
                        <div className="card card-test2">
                          <h5>{test.title}</h5>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div> */}

            {/* <div className="course-reco">
              <h4>Course Recommendations:</h4>
              <div className="courses-card">
                <Swiper
                  spaceBetween={10}
                  slidesPerView={1}
                  autoplay={{
                    delay: 1000,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 50,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 50,
                    },
                  }}
                >
                  {courses.map((course, index) => (
                    <SwiperSlide key={index}>
                      <div className="col-md-4">
                        <div className="card card-1">
                          <img src={course.img} alt="img" />
                        </div>
                        <div className="card card-2">
                          <div className="description">
                            <h5>{course.title}</h5>
                            <div className="time">
                              <div className="time-2">
                                <FiClock className="mx-2" />
                                <p>{course.duration}</p>
                              </div>
                              <div className="rating">
                                <FaRegStar className="mx-2 star" />
                                <p>{course.rating}</p>
                              </div>
                            </div>
                            <div className="courses-btn">
                              <Button className="button">
                                Know more <IoIosArrowForward />
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <PlayStore/>
    </>
  );
}

export default index;
