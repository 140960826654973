import React from "react";
// import Testimonials from "../../components/Home/Testimonials";
// import PopularCourse from "../../components/Course/PopularCourse/index";
// import BestCourse from "../../components/Course/BestCourse/index";
// import WideRange from "../../components/Course/WideRange/index";
import CourceHero from "../../components/Course/CourceHero/index";
import SomethingNew from "../../components/Course/SomethingNew/index";
import TopCompany from "../../components/Course/TopCompany/index";
import CourseSection from "../../components/Home/Course";

function index() {
  return (
    <>
      <div>
        <CourceHero />
        <TopCompany />
        <CourseSection />
        {/* <PopularCourse /> */}
        {/* <WideRange /> */}
        {/* <BestCourse /> */}
        {/* <Testimonials /> */}
        <SomethingNew />
      </div>
    </>
  );
}

export default index;
