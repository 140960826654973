import React from "react";
import "./index.css";
import logo1 from "../../../assets/company/azlogic.png";
import logo2 from "../../../assets/company/cootagecart.png";
import logo3 from "../../../assets/company/jerseyi.png";
import logo4 from "../../../assets/company/wiriya.png";
import logo5 from "../../../assets/company/simaji.png";
import logo6 from "../../../assets/company/realtime.png";
import logo7 from "../../../assets/company/petrolynk.png";
import logo8 from "../../../assets/company/liquid.png";
// import Calender from "./img/uil_calender.png";
// import Time from "./img/mingcute_time-line.png";
// import Ruppe from "./img/ri_money-rupee-circle-line.png";

const logos = [logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8];

function Index() {
  return (
    <React.Fragment>
      <div className="py-5" id="tch-main">
        {/* <div id="details-tch-ab">
          <div className="pt-4 pb-3 outer-tch-ab d-flex row px-4">
            <div className="px-3 col-md-4 col-sm-12 d-flex">
              <div>
                <div className="bg-tch-ab p-3">
                  <img src={Time} alt="loading" />
                </div>
              </div>
              <div className="px-3">
                <h4 className="heading">Online</h4>
                <p className="paragraph">8 Months</p>
              </div>
            </div>
            <div className="px-3 col-md-4 col-sm-12 d-flex">
              <div>
                <div className="bg-tch-ab p-3">
                  <img src={Calender} alt="loading" />
                </div>
              </div>
              <div className="px-3">
                <h4 className="heading">Online</h4>
                <p className="paragraph">8 Months</p>
              </div>
            </div>
            <div className="px-3 col-md-4 col-sm-12 d-flex">
              <div>
                <div className="bg-tch-ab p-3">
                  <img src={Ruppe} alt="loading" />
                </div>
              </div>
              <div className="px-3">
                <h4 className="heading">Online</h4>
                <p className="paragraph">8 Months</p>
              </div>
            </div>
          </div>
        </div> */}
        <div className="hdl-tc pt-5">
          <h1 className="hdl-tcc pt-3">Top Companies Hiring</h1>
        </div>
        <div className="py-5 px-5 d-flex">
          <div className="logo-tc">
            <div className="scrolling-logos">
              {logos.concat(logos).map((logo, index) => (
                <img key={index} src={logo} alt={`Logo ${index + 1}`} />
              ))}
            </div>
          </div>
        </div>
        <div id="details-tch" className="pb-3">
          <div className="p-3 outer-tch d-flex row">
            <div className="inner-box px-3 col-md-4 col-sm-12 ">
              <h1 className="tch-hdl">2000+</h1>
              <p className="tch-shl">Internship Opportunities</p>
            </div>
            <div className="inner-box px-4  col-md-4 col-sm-12 ">
              <h1 className="tch-hdl">₹40 LPA</h1>
              <p className="tch-shl">Highest Package Offered</p>
            </div>
            <div className="px-2 col-md-4 col-sm-12 px-4 ">
              <h1 className="tch-hdl ">₹4 LPA</h1>
              <p className="tch-shl">Min. Package Guarnteed</p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Index;
