import React from "react";
import AboutHero from "../../components/AboutCompany/AboutHero/index";
import Benifits from "../../components/AboutCompany/Benifits/index";
import LetsConnect from "../../components/AboutCompany/LetsConnect/index";
import FeaturedInternships from "../../components/AboutCompany/FeaturedInternships/index";
import Work from "../../components/AboutCompany/Works/index";
import SuccessStories from "../../components/AboutCompany/SuccessStories/index";
import CompanyMarquee from "../../components/AboutCompany/CompanyCard/index";

function index() {
  return (
    <>
      <div>
        <AboutHero />
        <CompanyMarquee />
        <Benifits />
        <Work />
        <SuccessStories />
        <FeaturedInternships />
        <LetsConnect />
      </div>
    </>
  );
}

export default index;
