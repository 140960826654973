import React from 'react'
import HeroBootCamp from './../../components/FreeBootCamp/AboutHeroBootCamp/index'

function index() {
  return (
    <div>
      <HeroBootCamp/>
    </div>
  )
}

export default index
