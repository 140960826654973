import React from "react";
import img1 from "../../assets/company/liquid.png";
import img2 from "../../assets/company/realtime.png";
import img3 from "../../assets/company/petrolynk.png";
import img4 from "../../assets/company/jerseyi.png";
import img5 from "../../assets/company/mizybook.png";
import img6 from "../../assets/company/wiriya.png";
import img7 from "../../assets/company/areliya.png";
import img8 from "../../assets/company/simaji.png";
import img9 from "../../assets/company/broadifi.png";
import img10 from "../../assets/company/azlogic.png";
import img11 from "../../assets/company/cootagecart.png";
import Marquee from "react-fast-marquee";
function companyCard() {
  return (
    <>
      <section id="home-company-card" className="home-company-card">
        <div className="title">
          <h2>Top Companies Hiring </h2>
        </div>
        <div className="marquee-home d-flex justify-content-center">
          <div className="card hero-card marquee-visible">
            <Marquee
              gradient={false}
              delay={0}
              speed={30}
              direction="left"
              style={{height:'180px'}}
            >
              <div className="d-flex justify-content-around align-items-center">
                <img src={img1} alt="" className="mx-4" />
                <img src={img2} alt="" className="mx-4" />
                <img src={img3} alt="" className="mx-4" />
                <img src={img4} alt="" className="mx-4" />
                <img src={img5} alt="" className="mx-4" />
                <img src={img6} alt="" className="mx-4" />
                <img src={img7} alt="" className="mx-4" />
                <img src={img8} alt="" className="mx-4" />
                <img src={img9} alt="" className="mx-4" />
                <img src={img10} alt="" className="mx-4" />
                <img src={img11} alt="" className="mx-4" />
              </div>
            </Marquee>
          </div>
        </div>
      </section>
    </>
  );
}

export default companyCard;
